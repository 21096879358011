import type { HeroBannerImageData, HeroBannerResponse } from '~/types/lexas/heroBanner.ts'
import type { TileBannerImageData, TileBannerResponse } from '~/types/lexas/tileBanner.ts'
import type { ProductTileResponse } from '~/types/lexas/productTileBanner.ts'
import type { CmsPageResponse } from '~/types/lexas/cmsPage'
import type { BlogPostResponse, CombinedBlogPost } from '~/types/lexas/blogPosts.ts'

export function useLexas() {
  const config = useRuntimeConfig()

  // Fetch hero banners based on path
  const getHeroBannerListByPath = async (path: string) => {
    try {
      // Convert homepage path to 'homepage'
      const bannerPath = path === '/' ? 'homepage' : path
      const data: HeroBannerResponse = await $fetch<HeroBannerResponse>(
        `https://${config.public.LEXASCMS_SPACE_ID}.spaces.lexascms.com/delivery/jsonapi/heroBannerList?filter[path][_eq]=${bannerPath}&page[limit]=1&include=banners.componentHeroBannerMobileImage,banners.componentHeroBannerDesktopImage`,
        {
          headers: {
            Authorization: `Bearer ${config.public.LEXASCMS_API_KEY}`
          }
        }
      )
      // Check if data is empty or missing, return an empty array if so
      if (!data || !data.included || data.included.length === 0) {
        return []
      }
      // Filter hero banners from the data
      // @ts-ignore
      const banners = data.included.filter(item => item.type === 'componentHeroBanner')
      // Filter images from the data
      // @ts-ignore
      const images = data.included.filter(item => item.type === 'coreImage')

      // Find an image by ID and returns attributes
      const findImageById = (imageId: string): HeroBannerImageData['attributes'] | null => {
        return images.find((item: HeroBannerImageData) => item.id === imageId)?.attributes || null
      }

      // Return a list of hero banners including mobile images, desktop images and links
      return banners.map((banner: any) => ({
        mobileImage: findImageById(banner.relationships.componentHeroBannerMobileImage.data.id),
        desktopImage: findImageById(banner.relationships.componentHeroBannerDesktopImage.data.id),
        link: banner.attributes.componentHeroBannerLink
      }))
    }
    catch (error) {
      console.error('Error:', error)
      return []
    }
  }

  const getTileBannerListByPath = async (path: string) => {
    try {
      // Convert homepage path to 'homepage'
      const bannerPath = path === '/' ? 'homepage' : path
      const data: TileBannerResponse = await $fetch<TileBannerResponse>(
        `https://${config.public.LEXASCMS_SPACE_ID}.spaces.lexascms.com/delivery/jsonapi/tileBannerList?filter[path][_eq]=${bannerPath}&page[limit]=1&include=banners,banners.componentTileBannerMobileImage,banners.componentTileBannerDesktopImage`,
        {
          headers: {
            Authorization: `Bearer ${config.public.LEXASCMS_API_KEY}`
          }
        }
      )

      // Check if data is empty or missing, return an empty array if so
      if (!data || !data.included || data.included.length === 0) {
        return []
      }
      // Filter tile banners from the data
      // @ts-ignore
      const banners = data.included.filter(item => item.type === 'componentTileBanner')

      // Filter images from the data
      // @ts-ignore
      const images = data.included.filter(item => item.type === 'coreImage')

      // Find an image by ID and returns attributes
      const findImageById = (imageId: string): TileBannerImageData['attributes'] | null => {
        return images.find((item: TileBannerImageData) => item.id === imageId)?.attributes || null
      }

      // Return list of banners including mobile and desktop images and links
      return banners.map((banner: any) => ({
        mobileImage: findImageById(banner.relationships.componentTileBannerMobileImage.data.id),
        desktopImage: findImageById(banner.relationships.componentTileBannerDesktopImage.data.id),
        link: banner.attributes.componentTileBannerLink
      }))
    }
    catch (error) {
      console.error('Error:', error)
      return []
    }
  }

  const getCategoryBannerListByPath = async (path: string) => {
    try {
      // Convert homepage path to 'homepage'
      const bannerPath = path === '/' ? 'homepage' : path
      const data: HeroBannerResponse = await $fetch<HeroBannerResponse>(
        `https://${config.public.LEXASCMS_SPACE_ID}.spaces.lexascms.com/delivery/jsonapi/categoryBannerList?filter[path][_eq]=${bannerPath}&page[limit]=1&include=banners.componentCategoryBannerMobileImage,banners.componentCategoryBannerDesktopImage`,
        {
          headers: {
            Authorization: `Bearer ${config.public.LEXASCMS_API_KEY}`
          }
        }
      )

      if (!data || !data.included || data.included.length === 0) {
        return []
      }
      // @ts-ignore
      const banners = data.included.filter(item => item.type === 'componentCategoryBanner')
      // @ts-ignore
      const images = data.included.filter(item => item.type === 'coreImage')

      const findImageById = (imageId: string): HeroBannerImageData['attributes'] | null => {
        return images.find((item: HeroBannerImageData) => item.id === imageId)?.attributes || null
      }

      return banners.map((banner: any) => ({
        mobileImage: findImageById(banner.relationships.componentCategoryBannerMobileImage.data.id),
        desktopImage: findImageById(banner.relationships.componentCategoryBannerDesktopImage.data.id),
        link: banner.attributes.componentCategoryBannerLink
      }))
    }
    catch (error) {
      console.error('Error:', error)
      return []
    }
  }

  const getCategoryProductTile = async () => {
    try {
      const data: ProductTileResponse = await $fetch<ProductTileResponse>(
        `https://${config.public.LEXASCMS_SPACE_ID}.spaces.lexascms.com/delivery/jsonapi/productListBanner?page[limit]=1&include=productListBannerMobileImage`,
        {
          headers: {
            Authorization: `Bearer ${config.public.LEXASCMS_API_KEY}`
          }
        }
      )

      if (!data || !data.included || data.included.length === 0) {
        console.warn('Error')
        return {
          mobileImageData: null
        }
      }

      const mobileImageData = data.included[0].attributes

      return {
        mobileImageData
      }
    }
    catch (error) {
      console.error('Error:', error)

      return {
        mobileImageData: null
      }
    }
  }

  const getCmsPage = (path: string) => {
    // Convert homepage path to 'homepage'
    const cmsPath = path === '/' ? 'homepage' : path
    return useFetch<CmsPageResponse>(
      `https://${config.public.LEXASCMS_SPACE_ID}.spaces.lexascms.com/delivery/jsonapi/sisaltosivu?filter[sisaltosivunUrlPolku][_eq]=${cmsPath}&include=sisltsivunKuva`,
      {
        headers: {
          Authorization: `Bearer ${config.public.LEXASCMS_API_KEY}`
        }
      }
    )
  }

  const getCmsChildPages = (path: string) => {
    // Convert homepage path to 'homepage'
    const cmsPath = path === '/' ? 'homepage' : path
    return useFetch<CmsPageResponse>(
      `https://${config.public.LEXASCMS_SPACE_ID}.spaces.lexascms.com/delivery/jsonapi/sisaltosivu?filter[sisaltosivunUrlPolku][_startsWith]=${cmsPath}`,
      {
        headers: {
          Authorization: `Bearer ${config.public.LEXASCMS_API_KEY}`
        }
      }
    )
  }
  // 100 is maximum you can fetch from LexasCMS. With "limit" you can restricts the number of fetch results.
  // getBlogPosts accepts numbers 1-100 to restrict the number of results.
  const getBlogPosts = (limit: number = 100) => {
    return useFetch<CombinedBlogPost[]>(
      `https://${config.public.LEXASCMS_SPACE_ID}.spaces.lexascms.com/delivery/jsonapi/blogiArtikkeli?include=blogiartikkelinKuva1&page[limit]=${limit}&sort=-blogiartikkelinJulkaisupaiva`,
      {
        headers: {
          Authorization: `Bearer ${config.public.LEXASCMS_API_KEY}`
        },
        transform: (data: any) => {
          const combinedData = data.data.map((item: any) => {
            const includedItem = data.included?.find(
              (incItem: any) => incItem.id === item.relationships.blogiartikkelinKuva1?.data?.id
            )
            return {
              ...item.attributes,
              assetId: includedItem?.attributes.assetId || null,
              altText: includedItem?.attributes.altText || null,
              url: includedItem?.attributes.url || null
            }
          })
          return combinedData
        }
      }
    )
  }

  const getBlogPost = (slug: string) => {
    return useFetch<BlogPostResponse>(
      `https://${config.public.LEXASCMS_SPACE_ID}.spaces.lexascms.com/delivery/jsonapi/blogiArtikkeli?filter[blogiartikkelinSlug][_eq]=${slug}&include=blogiartikkelinKuva1`,
      {
        headers: {
          Authorization: `Bearer ${config.public.LEXASCMS_API_KEY}`
        }
      }
    )
  }

  return {
    getHeroBannerListByPath,
    getTileBannerListByPath,
    getCategoryBannerListByPath,
    getCategoryProductTile,
    getCmsPage,
    getCmsChildPages,
    getBlogPosts,
    getBlogPost
  }
}
